<template>
  <div>
    <div class="orenji-layer layer1">
      <header-view />
      <div class="orenji-bg bg1"></div>
      <div class="orenji-home-content">
        <h2 class="fadeInDownBig animated delay1">クラウドサーバ</h2>
        <p v-show="notEn" class="txt fadeInDown animated delay2">
          安定、安全、弾性、高性能のクラウドコンピューティングサービス
        </p>
      </div>
    </div>
    <div class="orenji-layer layer2">
      <div class="orenji-bg bg2"></div>
      <div class="orenji-home-content">
        <h2 class="fadeInDownBig">クラウド</h2>
        <p v-show="notEn" class="txt">
          クラウドサーバーは、安全で信頼性の高いエラスティックコンピューティングサービスを提供します。わずか数分で、クラウドでCVMを取得して有効にし、コンピューティングのニーズを満たすことができます。ビジネス要件の変化に応じて、コンピューティングリソースをリアルタイムで拡張または削減できます。
          CVMは、実際に使用されたリソースに基づく課金をサポートしているため、計算コストを節約できます。
          CVMを使用すると、ソフトウェアとハ​​ードウェアの調達コストを大幅に削減し、ITの運用と保守を簡素化できます。
        </p>
      </div>
    </div>
    <div class="orenji-layer layer3">
      <div class="orenji-bg bg3"></div>
      <div class="orenji-home-content">
        <h2 style="font-size: 40px">概要</h2>
        <detail-view />
      </div>
    </div>
    <div class="orenji-layer layer4">
      <div class="orenji-bg bg4"></div>
      <div class="orenji-home-content">
        <h2 style="font-size: 40px">クラウド本体価格</h2>
        <price-view />
      </div>
    </div>
    <div class="orenji-layer layer5">
      <footer-view />
    </div>
  </div>
</template>

<script>
import headerView from "@/components/layout/header";
import footerView from "@/components/layout/footer";
import detailView from "@/components/orenji/detail";
import priceView from "@/components/orenji/price";

export default {
  name: "orenji",
  computed: {
    notEn() {
      return this.$i18n.locale !== "en";
    },
  },
  components: {
    headerView,
    footerView,
    detailView,
    priceView,
  },
};
</script>

<style lang="less" scoped>
.orenji-layer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  &.layer1 {
    top: 0;
  }

  &.layer2 {
    top: 100%;
  }

  &.layer3 {
    top: 200%;
    color: #333333;
    background: #f4f4f4;
  }

  &.layer4 {
    top: 300%;
    color: #333333;
    background: #ffffff;
  }

  &.layer5 {
    top: 400%;
    height: 219px;
  }
}

.orenji-bg {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;

  &.bg1 {
    background-image: url("../../../public/images/decloud.jpg");
  }

  &.bg2 {
    background-size: 800px;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-image: url("../../../public/images/cpu.png");
  }
}

.text {
  margin: 0 40px;
}

.orenji-home-content {
  width: 1320px;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  font-weight: normal;
  text-align: center;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  h2 {
    font-weight: 300;
    letter-spacing: 0.3rem;
    font-size: 60px;
    text-align: center;
    margin-bottom: 3rem;
  }

  >p {
    font-size: 20px;
  }
}

.durationDelay(@duration, @delay) {
  animation-duration: @duration;
  -webkit-animation-duration: @duration;
  animation-delay: @delay;
  -webkit-animation-delay: @delay;
}

.delay1 {
  .durationDelay(1s, 0.5s);
}

.delay2 {
  .durationDelay(1s, 1s);
}

.delay3 {
  .durationDelay(1s, 1.5s);
}

.delay4 {
  .durationDelay(1s, 2s);
}
</style>
