<template>
  <div class="menu-bar">
    <a href="/">
      <img class="logo" src="../../../public/images/icon/bplogo.svg" alt="logo" />
    </a>

    <ul class="menu">
      <li>
        <a href="/">{{ $t("lang.header.about") }}</a>
      </li>

      <li>
        <a href="javascript:;">{{ $t("lang.header.business") }}</a>
        <ul class="sub-menu">
          <li>
            <a href="/orenji">{{ $t("lang.header.orenji") }}</a>
          </li>

          <li>
            <a href="/oj/video">{{ $t("lang.header.video") }}</a>
          </li>

          <li>
            <a href="/oj/ecs">{{ $t("lang.header.ecs") }}</a>
          </li>

          <li>
            <a href="/mupao">{{ $t("lang.header.mupao") }}</a>
          </li>
        </ul>
      </li>

      <li>
        <a href="javascript:;">求人情报</a>
        <ul class="sub-menu">
          <li>
            <a target="_blank" href="/pdf/営業事務.pdf">営業事務</a>
          </li>

          <li>
            <a target="_blank" href="/pdf/一般事務.pdf">一般事務</a>
          </li>
        </ul>
      </li>

      <li>
        <a href="javascript:;">
          {{ lang | langFilter }}
          <span style="font-size: 12px">▼</span>
        </a>
        <ul class="sub-menu">
          <li :class="lang === 'en' ? 'active' : ''">
            <a @click="changeLanguage('en')">Int-English</a>
          </li>

          <li :class="lang === 'jp' ? 'active' : ''">
            <a @click="changeLanguage('jp')">Int-日本語</a>
          </li>

          <li :class="lang === 'zh' ? 'active' : ''">
            <a @click="changeLanguage('zh')">Int-简体中文</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "layout-header",
  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    this.init();
  },
  filters: {
    langFilter(lang) {
      switch (lang) {
        case "zh":
          return "简体中文";
        case "en":
          return "English";
        case "jp":
          return "日本語";
      }
    },
  },
  methods: {
    init() {
      const langList = ["zh", "en", "jp"];
      const localLang = localStorage.getItem("PB_LANG");
      if (this.oneOf(localLang, langList)) {
        this.$i18n.locale = localLang;
      }
    },
    changeLanguage(lang) {
      localStorage.setItem("PB_LANG", lang);
      location.reload();
    },
    oneOf(value, validList) {
      for (let i = 0; i < validList.length; i++) {
        if (value === validList[i]) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.menu-bar {
  width: 1200px;
  margin: 0 auto;
  padding-top: 28px;
  display: flex;
  position: absolute;
  top: 0;
  z-index: 99;
  left: 50%;
  justify-content: center;
  align-items: center;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  .logo {
    width: 130px;
    color: #fff;
  }
}

.menu {
  margin-left: 26px;

  >li {
    float: left;
    margin: 0 25px;
    line-height: 40px;
    position: relative;

    a {
      color: #d1dff2;
      font-weight: 500;
      font-size: 14px;
      text-decoration: none;

      &.router-link-active {
        color: #fff;
      }
    }

    &:hover {
      >a {
        color: #fff;
      }

      .sub-menu {
        display: block;
      }
    }

    &.active a {
      color: #fff;
    }
  }
}

.sub-menu {
  display: none;
  position: absolute;
  top: 39px;
  width: 150px;
  left: -20px;
  text-align: center;
  padding: 10px 0;
  box-sizing: border-box;
  z-index: 3;
  background-color: rgba(15, 25, 37, 0.5);
  overflow: auto;

  li {
    text-align: center;
    line-height: 30px;

    a {
      color: #b6c8e1;
    }

    &.active {
      a {
        color: #fff;
      }
    }

    &:hover a {
      color: #fff;
    }
  }
}
</style>
