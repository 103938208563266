<template>
  <div class="footer">
    <div class="footer-warp">
      <div class="footer-left">
        <h3>
          <b>BLUE POINTER</b>
        </h3>
        <p>BLUE POINTER{{ $t("lang.footer.company") }}</p>
        <p>
          <span>Tel：011-589-1901 </span>
          <span style="width: 20px; height: 10px; display: inline-block"></span>
          <span>
            Email：
            <a href="mailto:soumu@bluepointer.jp">soumu@bluepointer.jp</a>
          </span>
        </p>
        <p>
          <span>
            何でもお気軽にお問い合わせは、report@bluepointer.jp
            宛てにメールでお願いいたします。
          </span>
        </p>
      </div>
      <ul class="footer-right">
        <li>
          <ol>
            <li class="title">{{ $t("lang.footer.about") }}</li>
            <li>
              <a href="/">{{ $t("lang.header.about") }}</a>
            </li>
          </ol>
        </li>
        <li>
          <ol>
            <li class="title">{{ $t("lang.footer.link") }}</li>
            <li>
              <a target="_blank"  href="http://jp.orenji.jp">ORENJI</a>
            </li>
            <li>
              <a target="_blank"  href="http://jp.mupao.com">{{ $t("lang.footer.mupao") }}</a>
            </li>
          </ol>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "layout-footer",
};
</script>

<style lang="less" scoped>
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #0f1926;
  width: 100%;
  padding: 60px 0;

  &-warp {
    font-weight: normal;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &-left {
    text-align: left;

    >h3 {
      font-size: 20px;
      padding-bottom: 10px;
    }

    >p {
      margin-top: 10px;
      color: #999 !important;
      font-size: 16px;

      a {
        color: #999 !important;
      }
    }
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    >li {
      margin: 0 39px;

      ol {
        >li {
          text-align: left;
          margin: 10px 0;
          color: #999;
          font-size: 14px;

          &.title {
            margin-top: 0;
            margin-bottom: 20px;
            color: white;
          }
        }
      }
    }
  }
}
</style>
