<template>
  <div>
    <div class="mupao-layer layer1">
      <header-view />
      <div class="mupao-bg bg1"></div>
      <div class="mupao-home-content">
        <h2 class="fadeInDownBig animated delay1">
          {{ $t("lang.home.mupaoPage.title") }}
        </h2>
        <p v-show="notEn" class="txt fadeInDown animated delay2">
          {{ $t("lang.mupao.txt") }}
        </p>
        <p class="hr-full fadeInUp animated delay3"></p>
        <p class="txt base-size fadeInDown animated delay4">
          Bath advertising alliance is an online marketing company focusing on
          online advertising and online marketing.
        </p>
      </div>
    </div>
    <div class="mupao-layer layer2">
      <div class="mupao-bg bg2"></div>
      <mupaoContent>
        <a target="_blank" href="http://jp.mupao.com" class="jumpto">{{ $t("lang.mupao.jump") }}</a>
      </mupaoContent>
      <footer-view />
    </div>
  </div>
</template>

<script>
import headerView from "@/components/layout/header";
import mupaoContent from "@/components/widget/mupaoContent";
import footerView from "@/components/layout/footer";

export default {
  name: "mupao",
  computed: {
    notEn() {
      return this.$i18n.locale !== "en";
    },
  },
  components: {
    headerView,
    mupaoContent,
    footerView,
  },
};
</script>

<style lang="less" scoped>
.mupao-layer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  &.layer1 {
    top: 0;
  }

  &.layer2 {
    top: 100%;
  }
}

.mupao-bg {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;

  &.bg1 {
    background-image: url("../../public/images/mupao-page1.jpg");
  }

  &.bg2 {
    background-image: url("../../public/images/mupao-page2.jpg");
  }
}

.mupao-home-content {
  width: 800px;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  font-weight: normal;
  text-align: center;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  h2 {
    font-weight: 300;
    letter-spacing: 0.3rem;
    font-size: 40px;
    text-align: center;
  }
}

.durationDelay(@duration, @delay) {
  animation-duration: @duration;
  -webkit-animation-duration: @duration;
  animation-delay: @delay;
  -webkit-animation-delay: @delay;
}

.delay1 {
  .durationDelay(1s, 0.5s);
}

.delay2 {
  .durationDelay(1s, 1s);
}

.delay3 {
  .durationDelay(1s, 1.5s);
}

.delay4 {
  .durationDelay(1s, 2s);
}
</style>

