<template>
  <ul class="price-wrap">
    <li>
      <section>地域</section>
      <section>モデル</section>
      <section>メモリ</section>
      <section>クラウドディスク</section>
      <section class="line2">パブリックネットワーク帯域幅</section>
      <section>月額料金</section>
      <section>四半期ごとの価格</section>
      <section>年間価格</section>
      <section>支払い</section>
    </li>
    <li v-for="(itm, idx) in list" :class="{ head: idx === 0 }" :key="idx">
      <section>{{ itm.area }}</section>
      <section>{{ itm.type }}</section>
      <section>{{ itm.size }}</section>
      <section>{{ itm.disk }}</section>
      <section :class="{ line2: idx === 0 }">{{ itm.band }}</section>
      <section>{{ itm.month }}</section>
      <section>{{ itm.halfYear }}</section>
      <section>{{ itm.year }}</section>
      <section class="pay" @click="hanleTab(itm.month, idx)">
        購入
      </section>
    </li>
  </ul>
</template>

<script>
export default {
  name: "price",
  data() {
    return {
      list: [],
    };
  },
  async beforeMount() {
    const response = await fetch("/api/ecs/price");
    const { data } = await response.json();
    this.$data.list = data
  },
  methods: {
    hanleTab(item, index) {
      const ems = item.replace("円", "");
      this.$router.push(`/pay?money=${ems}&&number=${index}`);
    },
  },
};
</script>

<style lang="less" scoped>
.price-wrap {
  width: 1280px;
  margin: 0 auto;

  >li {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    color: #324065;

    >* {
      padding: 20px 30px;
    }

    .line2 {
      margin-top: -10px;
    }

    >section {
      &:nth-of-type(1) {
        width: 32px;
      }

      &:nth-of-type(2) {
        width: 48px;
      }

      &:nth-of-type(3) {
        width: 48px;
      }

      &:nth-of-type(4) {
        width: 128px;
      }

      &:nth-of-type(5) {
        width: 140px;
      }

      &:nth-of-type(6) {
        width: 75px;
      }

      &:nth-of-type(7) {
        width: 128px;
      }

      &:nth-of-type(8) {
        width: 85px;
      }
    }

    &.head {
      color: #195abe;
    }

    &:nth-of-type(2n-1) {
      background: rgba(25, 90, 190, 0.05);
    }
  }
}

.pay {
  cursor: pointer;
  color: #f08c1b;

  &:hover {
    text-decoration: underline;
    color: #f08c1b;
  }
}
</style>
