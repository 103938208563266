<template>
  <ul class="price-wrap">
    <li v-for="(itm, idx) in title" class="head" :key="idx + 999">
      <section>{{ itm.package }}</section>
      <section>{{ itm.time }}</section>
      <section>{{ itm.play }}</section>
      <section>{{ itm.money }}</section>
      <section>{{ itm.pay }}</section>
    </li>
    <li v-for="(itm, idx) in list" :key="idx">
      <section>{{ itm.package }}</section>
      <section>{{ itm.time }}</section>
      <section>{{ itm.play }}</section>
      <section>{{ itm.money }}</section>
      <section class="pay" @click="hanleTab(itm.money, idx + 8)">
        購入
      </section>
    </li>
  </ul>
</template>

<script>
export default {
  name: "price",
  data() {
    return {
      title: [
        {
          package: "パッケージ",
          time: "トランスコード時間",
          play: "再生回数",
          money: "価格",
          pay: "支払い",
        }],
      list: [],
    };
  },
  async beforeMount() {
    const response = await fetch("/api/vod/price");
    const { data } = await response.json();
    this.$data.list = data
  },
  methods: {
    hanleTab(item, index) {
      const ems = item.replace("円", "");
      this.$router.push(`/pay?money=${ems}&&number=${index}`);
    },
  },
};
</script>

<style lang="less" scoped>
.price-wrap {
  width: 1280px;
  margin: 0 auto;

  >li {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    color: #324065;

    >* {
      padding: 20px 30px;
    }

    .line2 {
      margin-top: -10px;
    }

    >section {
      &:nth-of-type(1) {
        width: 20%;
      }

      &:nth-of-type(2) {
        width: 20%;
      }

      &:nth-of-type(3) {
        width: 20%;
      }

      &:nth-of-type(4) {
        width: 20%;
      }

      &:nth-of-type(5) {
        width: 20%;
      }
    }

    &.head {
      color: #195abe;
    }

    &:nth-of-type(2n-1) {
      background: rgba(25, 90, 190, 0.05);
    }
  }
}

.pay {
  cursor: pointer;
  color: #f08c1b;

  &:hover {
    text-decoration: underline;
    color: #f08c1b;
  }
}
</style>
